import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import GET_ARTICLES from "../class/getArticles";

const [d, mq] = [document, window.matchMedia( "(max-width: 1023px)" )];
gsap.registerPlugin(ScrollTrigger);

class Blocks { 
  constructor() {
    this.init();
  }

  init() {
    this.information('.block-information__list[data-plugin="getArticles"]');
    this.parallaxItem('.mainvisual [data-plugin="parallax"]');
  }

  parallaxItem (q) {
    const elms = d.querySelectorAll(q);
    elms.forEach( elm => {
      const y = elm.getAttribute('data-y') || -100;
      const defY = elm.getAttribute('data-def-y') || 100;
      gsap.fromTo(elm, {
        y: defY
      },{
        y,
        scrollTrigger: {
          trigger: elm,
          start: "top bottom",
          end: "bottom top",
          scrub: true,
          // markers: true
        }
      })
    });
  }

  information (selector) {
    const information_block = new GET_ARTICLES(selector);
    information_block.makeItem = (item,content) => `
    <li class="block-${content}-item">
      <a href="/${content}/?mode=detail&amp;article=${item.id}" class="block-${content}-item__link">
        <div class="block-${content}-item__body">
          <div class="date-cate">
            <span class="date">${item.date}</span><span class="cate">${item.category.name}</span>
          </div>
          <h4 class="title ${item.is_new? 'is-new':''}">${(mq.matches)? item.title.str_truncate(24) : item.title.str_truncate(40)}</h4>
        </div>
      </a>
    </li>`;
    // information_block.makeItem = (item) => console.log(item);
    information_block.render();
  }
}

const domReady = TPL => new Blocks;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}